var changeFreq = 0;
var audioCtx = new (window.AudioContext || window.webkitAudioContext || window.audioContext);
var oscillator;



function beep(frequency, duration) {
    if (changeFreq > 0 ) {
        frequency = frequency +  Number(changeFreq);
    }
    if (typeof (oscillator) !== 'undefined') {
        oscillator.stop();
    }
    oscillator = audioCtx.createOscillator();
    var gainNode = audioCtx.createGain();
    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);

    gainNode.gain.value = 0.2;
    oscillator.type = 'square';
    oscillator.frequency.value = frequency;

    oscillator.start();

    setTimeout(
        function () {
            oscillator.stop();
        },
        duration
    );
}

export function beepSuccess() {
    beep(1500, 200);
    setTimeout(function () {
        beep(1700, 200);
    }, 250);
    setTimeout(function () {
        beep(1900, 200);
    }, 500);
}

export function beepError() {
    beep(1900, 200);
    setTimeout(function () {
        beep(1700, 200);
    }, 250);
    setTimeout(function () {
        beep(1500, 200);
    }, 500);
}

export function beepOk() {
    beep(1500, 180);
}
