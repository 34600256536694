<template>
  <v-container>
      <v-btn @click="orderDialog = true">Новый заказ</v-btn>
      <v-data-table v-if="!barcodeOnly" :items="orders" :headers="[
          {text: '№', value: 'id'},
          {text: 'Дата', value: 'date'},
          {text: 'Комментарий', value: 'comment'},
          {text: 'Кол-во', value: 'itemsCount'},
          {text: 'Сумма', value: 'itemsSum'},
          {text: 'Действия', value: 'actions'},
          ]">
          <template v-slot:item.actions="{item}">
              <v-btn fab x-small text @click="printDialog = true,orderId=item.id"><v-icon small>mdi-printer</v-icon></v-btn>
              <v-btn fab x-small text @click="orderId=item.id,orderCheckDialog=true"><v-icon small>mdi-file-check</v-icon></v-btn>
              <v-btn fab x-small text @click="orderId=item.id,orderDialog=true"><v-icon small>mdi-pencil</v-icon></v-btn>
              <v-btn fab x-small text @click="deleteOrder(item)"><v-icon small>mdi-delete</v-icon></v-btn>
          </template>
      </v-data-table>

      <v-dialog v-model="importDialog" v-if="importDialog" max-width="400">
          <v-card>
              <v-card-title>Импорт</v-card-title>
              <v-card-text>
                  <v-textarea v-model="importText" label="Сначала ШК потом кол-во"></v-textarea>
                  <v-select dense class="pr-6" style="width: 100px; font-size: 12px" v-model="selectedBarcode" :items="barcodeTypes" item-text="title" item-value="id" label="Штрихкоды"  clearable>
                  </v-select>
              </v-card-text>
              <v-card-actions>
                  <v-btn class="primary" @click="importOrder">Импорт</v-btn>
                  <v-btn text @click="importDialog = false">Отмена</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-if="orderDialog" v-model="orderDialog" max-width="900" scrollable>
          <v-form v-model="validOrder"
                  lazy-validation ref="order">
              <v-card>
                  <v-card-title>
                      Новый заказ <span v-if="productTotals.total">({{productTotals.total}} шт.)</span>
                      <v-spacer></v-spacer>
                      <v-btn fab x-small @click="addOrder" class="mr-5"><v-icon>mdi-folder-plus</v-icon></v-btn>
                      <v-btn fab x-small @click="importText='',importDialog=true"><v-icon>mdi-import</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>
                  <v-row>
                      <v-col>
                          <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field req
                                      v-model="dateFormatted"
                                      label="Дата"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                        :rules="[v => !!v  || 'Обязательное поле']"
                                        blur="date = parseDate(dateFormatted)"
                                      v-on="on"
                                  ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="date"
                                  no-title
                                  @input="menu1 = false"
                              ></v-date-picker>
                          </v-menu></v-col>
                      <v-col><v-text-field label="Комментарий" v-model="orderComment"></v-text-field></v-col>
                  </v-row>
                      <v-data-table v-if="!barcodeOnly" disable-pagination hide-default-footer dense :items="products"
                                    :headers="[{text: 'Наименование', value: 'product'},
                                    {text: 'Цвет', value: 'color'},
                                    {text: 'Размер', value: 'size'},
                                    {text: 'Кол-во', value: 'quantity'},
                                    {text: 'Цена', value: 'price'},
                                    {text: 'Действия', value: 'actions'}]">
                          <template v-slot:item.product="{item}">
                              {{productsList[item.sku_id].product}}
                          </template>
                          <template v-slot:item.size="{item}">
                              {{productsList[item.sku_id].size}}
                          </template>
                          <template v-slot:item.color="{item}">
                              {{productsList[item.sku_id].color}}
                          </template>
                          <template v-slot:item.quantity="{item}">
                              <v-text-field  append-outer-icon="mdi-plus"
                                            @click:append-outer="increment(item, 'quantity')"
                                            prepend-icon="mdi-minus"
                                            @click:prepend="decrement(item, 'quantity')" dense v-model="item.quantity" style="width: 92px; font-size: 14px"></v-text-field>
                          </template>
                          <template v-slot:item.price="{item}">
                              <v-text-field dense v-model="item.price" style="width: 72px; font-size: 14px"></v-text-field>
                          </template>
                          <template v-slot:item.actions="{item}">
                              <v-btn fab x-small text><v-icon small>mdi-printer</v-icon></v-btn>
                              <v-btn fab x-small text @click="delItem(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                          </template>
                      </v-data-table>
                  </v-card-text>
                  <v-card-actions>
                      <v-overflow-btn v-if="!barcodeOnly" editable dense class="pr-6" style="width: 180px" v-model="selectedProduct" :items="productsArray" item-text="title" item-value="sku_id" label="Поиск" clearable>
                      </v-overflow-btn>
                      <v-select v-if="!barcodeOnly" dense class="pr-6" style="width: 140px" v-model="selectedBarcode" :items="barcodeTypes" item-text="title" item-value="id" label="Штрихкод" clearable>
                      </v-select>
                      <v-spacer></v-spacer>
                      <v-text-field @keyup.enter="findByBarcode(false)" v-model.lazy="searchBarcode" label="Штрихкод" append-outer-icon="mdi-eye-off" @click:append-outer="barcodeOnly=!barcodeOnly"></v-text-field>
                      <v-spacer></v-spacer>
                      <v-btn class="primary" @click="saveOrder">Сохранить</v-btn>
                      <v-btn text @click="orderDialog = false">Закрыть</v-btn>
                  </v-card-actions>
              </v-card>
          </v-form>
      </v-dialog>

      <v-dialog v-if="orderCheckDialog" v-model="orderCheckDialog" persistent max-width="700" scrollable>
          <v-card>
              <v-card-title>
                  Проверка заказа
                  <v-spacer></v-spacer>
                  <v-select dense class="pr-6" style="width: 160px; font-size: 12px" v-model="showOptions" :items="[{text: 'Все', value: 'all'}, {text: 'Только проверенные', value: 'checked'}, {text: 'Только с ошибками', value: 'errors'}, {text: 'Непроверенные и с ошибками', value: 'both'}]" label="Показать"  >
                  </v-select>
                  <v-spacer></v-spacer>
                  <v-select dense class="pr-6" style="width: 100px; font-size: 12px" v-model="selectedBarcode" :items="barcodeTypes" item-text="title" item-value="id" label="Штрихкоды"  clearable>
                  </v-select>
                  <v-spacer></v-spacer>
                  <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              x-small
                          >
                              История
                          </v-btn>
                      </template>
                      <v-card
                          class="mx-auto"
                          max-width="300"
                          tile
                      >
                        <v-list>
                      <v-virtual-scroll
                          :items="barcodeHistory"
                          height="200"
                          width="300"
                          item-height="50"
                      >
                          <template v-slot:default="{ item }">
                          <v-list-item style="border-bottom: 1px solid #ccc">
                              <v-list-item-title style=" white-space: normal"><strong>{{ item.time }}:</strong> {{ item.product}}
                              </v-list-item-title>
                          </v-list-item>
                          </template>
                      </v-virtual-scroll>
                        </v-list>
                      </v-card>
                  </v-menu>
              </v-card-title>
              <v-card-text>
                  <v-data-table v-if="!barcodeOnly" disable-pagination hide-default-footer :headers="[
                      {text: 'Наименование', value: 'product'},
                      {text: 'Цвет', value: 'color'},
                      {text: 'Размер', value: 'size'},
                      {text: 'Кол-во', value: 'quantity'},
                      {text: 'Результат', value: 'result'},
                  ]" :items="products">
                      <template v-slot:body="{ items }">
                          <tbody>
                          <template v-for="item in items" >
                              <tr v-if="checkVisibility(item)" :key="item.sku_id" :class="{ 'touchedRow': item.sku_id == lastId,'rowOk':  checkResult[item.sku_id] == item.quantity, 'rowError': checkResult[item.sku_id] > item.quantity}">
                                  <td>{{productsList[item.sku_id].product}}</td>
                                  <td>{{productsList[item.sku_id].size}}</td>
                                  <td>{{productsList[item.sku_id].color}}</td>
                                  <td>{{item.quantity}}</td>
                                  <td>{{checkResult[item.sku_id]}}</td>
                              </tr>
                          </template>
                          </tbody>
                      </template>
                  </v-data-table>
              </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    Всего: {{productTotals.totalChecked}} из {{productTotals.total}} <span v-if="productTotals.extra">(+{{productTotals.extra}} лишний)</span>
                    <v-spacer></v-spacer>

                    <v-text-field @keyup.enter="findByBarcode(true)" v-model.lazy="searchBarcode" label="Штрихкод" append-outer-icon="mdi-eye-off" @click:append-outer="barcodeOnly=!barcodeOnly"></v-text-field>

                    <v-spacer></v-spacer>

                    <v-btn @click="saveOrderWithChanges(false)">Изменить заказ</v-btn>
                    <v-btn text @click="orderCheckDialog=false">Закрыть</v-btn>
                </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="printDialog" v-if="!barcodeOnly && printDialog" max-width="300">
          <v-card>
              <v-card-title>Печать</v-card-title>
              <v-card-text>
                  <v-select v-model="selectedReport" :items="reportsList" item-value="id" item-text="title" return-object>

                  </v-select>
              </v-card-text>
              <v-card-actions>
                  <v-btn class="primary" @click="printSticker">Печать</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-overlay :value="overlay" v-if="!barcodeOnly" style="z-index:1100">

          <div>
              <v-alert v-if="!errorMessage" type="success" >
                  Успешно
              </v-alert>
              <v-alert v-else type="error" >
                  {{errorMessage}}
              </v-alert>
          </div>
          <div @click="overlay = false" style="position: fixed; top:0; bottom: 0; left: 0; right:0; background-color: rgba(255,255,255,0); opacity: 0.1"></div>

      </v-overlay>
      <v-overlay :value="progress" v-if="!barcodeOnly" style="z-index:1000">
          <div class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  style="z-index:1001"
              ></v-progress-circular>
          </div>
      </v-overlay>


  </v-container>
</template>

<style>
    .rowOk {
        background-color: #a7c8a0;
    }

    .rowError {
        background-color: #c8a191;
    }

    .touchedRow {
        background-color: #ffffd2;
    }

    .v-card__title .v-select__slot .v-label {
        font-size: 12px;
    }
</style>

<script>
  // import print from 'print-js'

  import axios from "axios";
  import * as sound from  './../resources/sound.js'
  import print from "print-js";

  export default {

      data: () => ({
          importDialog: false,
          importText: '',
          validOrder: true,
          reportsList: [],
          printDialog: false,
          selectedReport: null,
          showOptions: 'all',
          productTotals: {total: 0, totalChecked: 0, extra: 0},
          lastId: null,
          barcodeHistory: [],
          orderCheckDialog: false,
          searchBarcode: '',
          orderId: null,
          selectedProduct: null,
          selectedBarcode: null,
          orders: [],
          productsList: {},
          barcodes: [],
          barcodeTypes: [],
          products: [],
          orderComment: '',
          date: new Date().toISOString().substr(0, 10),
          dateFormatted: '',
          menu1: false,
          orderDialog: false,
          overlay: false,
          errorMessage: '',
          progress: false,
          checkResult: {},
          rowTimeout: null,
          barcodeOnly: false
      }),
      methods: {
          importOrder() {
              if (this.importText.trim() != ''){
                  let items = this.importText.split(/\n/);
                  console.log(items);
                  for (let i in items) {
                      let item = items[i].split(/\t/);
                      for (let i1 = 0;i1<item[1];i1++) {
                          this.searchBarcode = item[0].trim();
                          this.findByBarcode(false);
                      }
                  }
                  this.checkTotals(false);
              }
          },
          addOrder() {
              let num = prompt('Введите номер заказа, позиции из которого хотите добавить');
              if (num) {
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/order/order',
                      method: 'GET',
                      params: {id: num}
                  }).then(function (response) {
                      if (response.data.order) {
                          let q;
                          for (let i in response.data.items) {
                              q = 0;
                              while (q < response.data.items[i].quantity) {
                                  $this.addProduct(response.data.items[i].sku_id);
                                  q++;
                              }
                          }
                          $this.progress = false;
                          $this.checkTotals(true);
                      } else {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          printSticker() {
              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/report/labels',
                  method: 'POST',
                  responseType: 'blob',
                  data: {
                      id: this.orderId,
                      report: this.selectedReport.id,
                      type: 'orderlabels',
                  }
              }).then(function (response) {
                  if (!response.data.error) {
                      let blob = null;
                      if ($this.selectedReport.type == 'pdf') {
                          $this.progress = false;
                          $this.printDialog = false;
                          blob = new Blob([response.data], {type: 'application/pdf'});
                          var url = URL.createObjectURL(blob);
                          print(url);
                      } else {
                          $this.printDialog = false;
                          $this.progress = false;
                          blob = new Blob([response.data], {type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                          const downloadLink = document.createElement('a');
                          downloadLink.href = URL.createObjectURL(blob);
                          downloadLink.download = $this.selectedReport.title + '.xlsx';
                          document.body.appendChild(downloadLink);
                          downloadLink.click();
                          URL.revokeObjectURL(downloadLink.href);
                          document.body.removeChild(downloadLink);
                      }

                  } else {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });
          },
          saveOrderWithChanges(dontNeedConfirm) {
              if (dontNeedConfirm || confirm('Вы действительно хотите изменить заказ?')) {
                  for (let i in this.products) {
                      if (!this.checkResult[this.products[i].sku_id] || this.checkResult[this.products[i].sku_id] < 1) {
                          this.products.splice(i, 1);
                          return this.saveOrderWithChanges(true);
                      } else {
                          this.products[i].quantity = this.checkResult[this.products[i].sku_id];
                      }
                  }
                  this.saveOrder();
              }
              return true;
          },
          checkVisibility(item) {
              if (this.showOptions == 'all') {
                  return true;
              }
              if (this.showOptions == 'errors') {
                  if (this.checkResult[item.sku_id] && item.quantity < this.checkResult[item.sku_id]) {
                      return true;
                  }
              }
              if (this.showOptions == 'checked') {
                  if (this.checkResult[item.sku_id] && item.quantity == this.checkResult[item.sku_id]) {
                      return true;
                  }
              }
              if (this.showOptions == 'both') {
                  if (!this.checkResult[item.sku_id] || (this.checkResult[item.sku_id] && item.quantity > this.checkResult[item.sku_id]) || (this.checkResult[item.sku_id] && item.quantity < this.checkResult[item.sku_id])) {
                      return true;
                  }
              }
              return false;
          },
          findByBarcode(check) {
              var t0 = performance.now();
              if (this.searchBarcode && this.searchBarcode.trim() != '') {
                  let str = this.searchBarcode.trim() + ':';
                  if (this.selectedBarcode) {
                      str = str + this.selectedBarcode;
                  }

                  for (let i in this.barcodes) {
                      let checkRes = false;
                      if (this.selectedBarcode) {
                          checkRes = i == str;
                      } else {
                          checkRes = i.startsWith(str);
                      }
                      if (checkRes) {
                          if (!check) {
                              this.addProduct(this.barcodes[i]);
                              this.searchBarcode = '';
                              if (!this.importDialog)
                              sound.beepOk();
                          } else {
                              if (this.checkResult[this.barcodes[i]]) {
                                  this.checkResult[this.barcodes[i]]++;
                              } else {
                                  this.checkResult[this.barcodes[i]] = 1;
                              }
                              let isAll = true;
                              let found = false;
                              for (let i1 in this.products) {
                                  if (this.products[i1].sku_id == this.barcodes[i]) {
                                      if (this.products[i1].quantity >= this.checkResult[this.barcodes[i]]) {
                                          sound.beepOk();
                                      } else {
                                          sound.beepError();
                                      }
                                      this.lastId = null;
                                      this.$nextTick(() => {
                                          this.lastId = this.barcodes[i];
                                      });
                                      let now = new Date();
                                      found = true;
                                      this.barcodeHistory.splice(0, 0, {
                                          time: now.toLocaleTimeString(),
                                          product: this.productsList[this.barcodes[i]].product + ' (' + this.productsList[this.barcodes[i]].color + '/' + this.productsList[this.barcodes[i]].size + ')'
                                      })
                                  }
                                  if (!this.checkResult[this.products[i1].sku_id] || this.products[i1].quantity > this.checkResult[this.products[i1].sku_id]) {
                                      isAll = false;
                                  }
                              }
                              if (!found) {
                                  this.products.splice(this.products.length, 0, {
                                      sku_id: this.barcodes[i],
                                      product_id: this.productsList[this.barcodes[i]].product_id,
                                      color_id: this.productsList[this.barcodes[i]].color_id,
                                      size_id: this.productsList[this.barcodes[i]].size_id,
                                      price: this.productsList[this.barcodes[i]].price,
                                      quantity: 0,
                                  });
                                  let now = new Date();
                                  if (!this.importDialog)
                                  sound.beepError();
                                  this.barcodeHistory.splice(0, 0, {
                                      time: now.toLocaleTimeString(),
                                      product: this.productsList[this.barcodes[i]].product + ' (' + this.productsList[this.barcodes[i]].color + '/' + this.productsList[this.barcodes[i]].size + ')'
                                  })

                              }
                              if (isAll) {
                                  if (!this.importDialog)
                                  sound.beepSuccess();
                              }
                              this.searchBarcode = '';

                          }
                          if (!this.importDialog)
                          this.checkTotals(check);
                          var t1 = performance.now();
                          console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
                          return;
                      }
                  }
              }
              this.searchBarcode = '';
              sound.beepError();
          },
          saveOrder() {
              if (!this.$refs.order || this.$refs.order.validate()) {

                  let $this = this;
                  this.progress = true;

                  axios({
                      url: '/api/order/save',
                      params: {id: this.orderId},
                      method: 'POST',
                      data: {
                          onlyItems: this.orderCheckDialog,
                          comment: this.orderComment,
                          date: this.date,
                          items: this.products
                      }
                  }).then(function (response) {
                      if (response.data.orders) {
                          $this.orders = response.data.orders;
                          $this.productsList = response.data.productsList;
                          $this.barcodes = response.data.barcodes;
                          $this.barcodeTypes = response.data.barcodeTypes;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                          $this.orderDialog = false;
                          $this.orderCheckDialog = false;
                      } else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          delItem(item) {
              for (let i in this.products) {
                  if (this.products[i].sku_id == item.sku_id) {
                      this.products.splice(i, 1);
                  }
              }
          },
          increment(item, field) {
              item[field] = parseInt(item[field], 10) + 1
          },
          decrement(item, field) {
              if (item[field] > 1 || (item[field] > 0 && field != 'quantity'))
                  item[field] = parseInt(item[field], 10) - 1
          },
          addProduct(id) {
              let found = false;
              for (let i in this.products) {
                  if (this.products[i].sku_id == id) {
                      this.products[i].quantity = this.products[i].quantity + 1;
                      found = true;
                      break;
                  }
              }
              if (!found) {
                  this.products.splice(this.products.length, 0, {
                      sku_id: id,
                      quantity: 1,
                      price: this.productsList[id].price
                  });
              }
          },
          formatDate(date) {
              if (!date) return null

              const [year, month, day] = date.split('-')
              return `${day}.${month}.${year}`
          },
          parseDate(date) {
              if (!date) return null

              const [day, month, year] = date.split('.')
              return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
          },
          checkTotals(check) {
              let ret = {total: 0, totalChecked: 0, extra: 0};
              for (let i in this.products) {
                  ret.total = ret.total + this.products[i].quantity;
                  if (check) {
                      if (this.checkResult[this.products[i].sku_id]) {
                          if (this.checkResult[this.products[i].sku_id] <= this.products[i].quantity) {
                              ret.totalChecked += this.checkResult[this.products[i].sku_id];
                          } else {
                              ret.totalChecked += this.products[i].quantity;
                              ret.extra = this.checkResult[this.products[i].sku_id] - this.products[i].quantity;
                          }
                      }
                  }
              }
              this.productTotals = ret;
          },
          loadOrder() {

              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/order/order',
                  method: 'GET',
                  params: {id: this.orderId}
              }).then(function (response) {
                  if (response.data.order) {
                      $this.products = response.data.items;
                      $this.orderComment = response.data.order.comment;
                      $this.date = response.data.order.date;
                      $this.progress = false;
                      $this.checkTotals(true);
                  } else {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });


          }
      },
      computed: {

          computedDateFormatted () {
              return this.formatDate(this.date)
          },
          productsArray() {
              let ret = [];
              for (let i in this.productsList) {
                  ret.push(Object.assign(this.productsList[i], {title: this.productsList[i].product + ' (' + this.productsList[i].color + '/' + this.productsList[i].size+')'}))
              }
              return ret;
          }
      },

      watch: {
          printDialog() {
              if (this.printDialog) {
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/report/get',
                      method: 'GET',
                  }).then(function (response) {
                      if (!response.data.error) {
                          $this.reportsList = response.data.reports;
                          $this.progress = false;
                      }
                      else {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
              else {
                  this.orderId = null;
              }
          },
          lastId() {

              if (this.lastId) {
                  if (this.rowTimeout) {
                      clearTimeout(this.rowTimeout);
                      this.rowTimeout = null;
                  }
                  let $this = this;
                  this.$nextTick(() => {
                      let row = document
                          .querySelector(".touchedRow");
                      if (row) {
                          row.scrollIntoView({ behavior: "smooth" });
                      }

                  });

                  this.rowTimeout = setTimeout(function() {
                      $this.lastId = null;
                      $this.rowTimeout = null;
                  }, 1000);

              }
          },
          selectedProduct() {
              if (this.selectedProduct) {
                  this.addProduct(this.selectedProduct);
                  this.$nextTick(() => {
                      this.selectedProduct = null;
                  })
              }
          },
          orderCheckDialog() {
              if (this.orderCheckDialog) {
                  this.loadOrder();

              } else {
                  this.orderId = null;
                  this.products = [];
                  this.barcodeHistory = [];
                  this.checkResult = {};
                  this.showOptions = 'all';
                  this.barcodeOnly = false;
              }
          },
          orderDialog () {
              if (this.orderDialog) {
                  if (this.orderId) {
                      this.loadOrder();
                  }
              }
              else {
                  this.orderId = null;
                  this.date = null;
                  this.orderComment = null;
                  this.products = [];
                  this.barcodeOnly = false;
              }
          },
          date() {
              this.dateFormatted = this.formatDate(this.date)
          },
      },
      mounted() {
          this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10));
          let $this = this;
          this.progress = true;
          axios({
              url: '/api/order/orders',
              method: 'GET',
          }).then(function (response) {
              if (response.data.productsList) {
                  $this.orders = response.data.orders;
                  $this.productsList = response.data.productsList;
                  $this.barcodes = response.data.barcodes;
                  $this.barcodeTypes = response.data.barcodeTypes;
                  $this.progress = false;
              }
              else {
                  $this.errorMessage = response.data.error;
                  $this.overlay = true;
                  $this.progress = false;
              }
          })
              .catch(function () {
                  $this.errorMessage = 'Ошибка';
                  $this.overlay = true;
                  $this.progress = false;
              });

      }
  }
</script>
